import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CustomSnackbarModule } from '@ngx-common/components/custom-snackbar/custom-snackbar.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from '@environment';
import { UnauthorizedInterceptor } from '@ngx-common/interceptors/unauthorized.interceptor';
import { JwtInterceptor } from '@ngx-common/interceptors/jwt.interceptor';
import { CustomSentryErrorHandler } from '@ngx-common/services/custom-sentry-handler.service';
import { DecimalPipe, UpperCasePipe } from '@angular/common';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { ClsAlertsModule } from '@ngx-common-v2/components/cls-alerts/cls-alerts.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,

		TranslateModule.forRoot(),
		MatSnackBarModule,
		ClsAlertsModule,

		MatButtonModule,
		MatProgressBarModule,
		CustomSnackbarModule,
	],
	providers: [
		DecimalPipe,
		UpperCasePipe,
		{
			provide: NgxCommonPagesConfig.key,
			useValue: {
				HOME_PAGE_ROUTING: '/',
				APP: ECopyleaksAPP.Admin,
				APP_ORIGIN: environment.idUrl,
				IS_SSR_PROJECT: false,
			} as NgxCommonPagesConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		// ** Sentry Error Logging **
		{
			provide: ErrorHandler,
			useClass: CustomSentryErrorHandler,
		},
		{
			provide: MAT_CHIPS_DEFAULT_OPTIONS,
			useValue: {
				separatorKeyCodes: [COMMA, SPACE],
			},
		},
		// *************************
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
