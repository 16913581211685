import { DOCUMENT } from '@angular/common';
import {
	Compiler,
	Component,
	Inject,
	Injector,
	LOCALE_ID,
	NgModuleFactory,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { ChangeQuillEditorToolbarIcons } from '@ngx-common-v2/utils/quill-editor.utils';
import { ECookiesKeys } from '@ngx-common/enums/cookies-keys.enum';
import { AppDirectionalityService } from '@ngx-common/services/app-directionality.service';
import { AuthService } from '@ngx-common/services/auth.service';
import { EnvironmentService } from '@ngx-common/services/enviroments.service';
import { LocalStorageService } from '@ngx-common/services/localstorage.service';
import { UrlLocationService } from '@ngx-common/services/url-location.service';
import { setAppInjector } from '@ngx-common/utils/angular-injector.utils';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import Quill from 'quill';
import { MainLayoutRouting } from './main-layout/models/main-layout-routing.model';
import { filter, take } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styles: [
		`
			.navigation-progress-bar {
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1100;
			}
		`,
	],
})
export class AppComponent implements OnInit {
	@ViewChild('flashAlertsContainer', { read: ViewContainerRef })
	flashAlertsContainer: ViewContainerRef;
	pageLoading = false;
	showCookieMsg: boolean;
	private _isReloadMessageShown: boolean = false;
	constructor(
		public injector: Injector,
		private _router: Router,
		private _ngxTranslateSvc: TranslateService,
		private _clsAlertsSvc: ClsAlertsService,
		private _authSvc: AuthService,
		private _compiler: Compiler,
		private _urlLocationSvc: UrlLocationService,
		private _localStorageSvc: LocalStorageService,
		private _cookiesSvc: CookieService,
		private readonly dir: AppDirectionalityService,
		private _renderer: Renderer2,
		private _envSvc: EnvironmentService,
		@Inject(DOCUMENT) private _document: Document,
		@Inject(LOCALE_ID) private _locale: string
	) {
		setAppInjector(injector);
	}

	async ngOnInit() {
		// #region translation setup
		this._envSvc.initEnvironementVariables();
		this.setupLanguage();

		this._ngxTranslateSvc.setDefaultLang('en');
		this._ngxTranslateSvc.use(this._locale);

		if (this.dir.value === 'rtl') {
			this._renderer.addClass(this._document.body, `copyleaks-rtl`);
		}

		this._handleDynamicModulesLazyLoad();

		this._setupRoutingHandlers();
		setTimeout(() => {
			this._authSvc.checkIfUserAlreadyLoggedin();
		}, 1000);
		if (!this._localStorageSvc.getItem('allowCookies')) {
			this.showCookieMsg = true;
		}

		var icons = Quill.import('ui/icons');
		icons = ChangeQuillEditorToolbarIcons(icons);
	}

	public _handleDynamicModulesLazyLoad() {
		// flash alerts module
		this._authSvc.isLogedIn$
			.pipe(
				filter(isLogedIn => isLogedIn),
				take(1)
			)
			.subscribe(_ => {
				// using timeout coz module loading should not run on the main thread.
				setTimeout(() => {
					this._loadFlashAlertsModule();
				}, 0);
			});
		// ------------------
	}

	private async _loadFlashAlertsModule() {
		const { FlashAlertsModule } = await import('@ngx-common/components/flash-alerts/flash-alerts.module');
		const { FlashAlertsComponent } = await import('@ngx-common/components/flash-alerts/flash-alerts.component');
		const moduleFactory =
			FlashAlertsModule instanceof NgModuleFactory
				? FlashAlertsModule
				: await this._compiler.compileModuleAsync(FlashAlertsModule);
		const moduleRef = moduleFactory.create(this.injector);
		const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(FlashAlertsComponent);
		this.flashAlertsContainer?.clear();
		this.flashAlertsContainer?.createComponent(componentFactory);
	}

	private setupLanguage(): void {
		if (environment.production) {
			const baseHref = this._document.getElementsByTagName('base')[0].attributes[0].value;
			let localstorageLang = this._cookiesSvc.get(ECookiesKeys.Langauge);

			if (this._locale.toLowerCase() == 'en-us') this._locale = 'en';
			if (localstorageLang.toLowerCase() == 'en-us') localstorageLang = 'en';

			if (localstorageLang) {
				if (baseHref === `/` && this._locale === 'en' && localstorageLang !== 'en') {
					// Enter to the web with '/' but with localStorage inside
					const pageURL = this._urlLocationSvc.location.pathname;
					const queryParams = this._urlLocationSvc.location.search;
					setTimeout(() => {
						this._urlLocationSvc.location.href = `${localstorageLang}${pageURL}${queryParams}`;
					}, 1000);
				} else if (this._locale !== localstorageLang && this._locale !== 'en') {
					this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', this._envSvc.getDomain());
				}
			} else {
				this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', this._envSvc.getDomain()); // Covers first time going to the website
			}
		}
	}

	private async _setupRoutingHandlers() {
		this._router.events.subscribe(event => {
			// if this is an EU user and tries to access the AI GRC pages then redirect to the home page
			if (
				this._urlLocationSvc?.location?.pathname?.startsWith(`/${MainLayoutRouting.AI_GRC}`) &&
				this._envSvc.isEuUser
			) {
				const _location = this._urlLocationSvc.location;
				_location.href = ``;
			}

			switch (true) {
				case event instanceof NavigationStart:
					setTimeout(() => {
						this.pageLoading = true;
					});
					break;
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError:
					setTimeout(() => {
						this.pageLoading = false;
					});
					if (event instanceof NavigationError && event?.error?.name === 'ChunkLoadError') {
						if (!this._isReloadMessageShown) {
							this._isReloadMessageShown = true;
							this._clsAlertsSvc.showVersionUpdateMessage(() => {
								const _location = this._urlLocationSvc.location;
								_location.href = `${_location.origin}${event.url}`;
								this._isReloadMessageShown = false;
							});
							// const message = this._snackbarSvc.showVersionUpdateMessage();
							// message
							// 	.onAction()
							// 	.pipe(takeWhile(() => this._isReloadMessageShown))
							// 	.subscribe(() => {
							// 		const _location = this._urlLocationSvc.location;
							// 		setTimeout(() => {
							// 			_location.href = `${_location.origin}${event.url}`;
							// 		}, 1000);
							// 	});
							// message
							// 	.afterDismissed()
							// 	.pipe(takeWhile(() => this._isReloadMessageShown))
							// 	.subscribe(() => {
							// 		this._isReloadMessageShown = false;
							// 	});
						}
					}
					break;
				default:
					break;
			}
		});
	}
}
