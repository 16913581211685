<!-- <mat-progress-bar
	mode="indeterminate"
	class="navigation-progress-bar"
	value="40"></mat-progress-bar> -->

<router-outlet></router-outlet>

<cls-alerts></cls-alerts>

<!-- flash alerts -->
<ng-container #flashAlertsContainer></ng-container>

<!-- <app-allow-cookies-msg (closeCookieMsg)="showCookieMsg = false" *ngIf="showCookieMsg"></app-allow-cookies-msg> -->
