import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ORG_ROLES } from '@ngx-common/constants/organization-roles.consts';
import { EnviromentsLoadGuard } from '@ngx-common/guards/enviroments.guard';
import { IncompleteOrganizationUserInfoGuard } from '@ngx-common/guards/incomplete-organization-user-info.guard';
import { LoggedInGuard } from '@ngx-common/guards/logged-in.guard';
import { SEOGuard } from '@ngx-common/guards/seo.guard';
import { AccountPagesRoutes } from '@ngx-common/pages/account-pages/model/account-routings.model';
import { MainLayoutRouting } from './main-layout/models/main-layout-routing.model';
import { DashboardErrorPagesRedirectsGuard } from './guards/error-pages-redirects.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./main-layout/main-layout.module').then(mod => mod.MainLayoutModule),
		canActivate: [EnviromentsLoadGuard],
	},
	{
		path: AccountPagesRoutes.LoginRedirect,
		loadChildren: () =>
			import('@ngx-common/pages/login-redirect/login-redirect.module').then(mod => mod.LoginRedirectModule),
		canActivate: [EnviromentsLoadGuard, SEOGuard],
	},
	{
		path: AccountPagesRoutes.OrganizationDetails,
		loadChildren: () =>
			import('./layouts/organization-details-page/organization-details-page.module').then(
				mod => mod.OrganizationDetailsPageModule
			),
		canActivate: [EnviromentsLoadGuard, IncompleteOrganizationUserInfoGuard],
	},
	{
		path: AccountPagesRoutes.OrganizationAddSeats,
		loadChildren: () =>
			import('./layouts/add-seats-layout/add-seats-layout.module').then(mod => mod.AddSeatsLayoutModule),
		canActivate: [EnviromentsLoadGuard, LoggedInGuard],
	},
	{
		path: AccountPagesRoutes.AddSeats,
		loadChildren: () =>
			import('./layouts/add-seats-layout/add-seats-layout.module').then(mod => mod.AddSeatsLayoutModule),
		canActivate: [EnviromentsLoadGuard, IncompleteOrganizationUserInfoGuard],
	},
	{
		path: MainLayoutRouting.PAYMENT_MAIN_PAGE,
		loadChildren: () => import('./layouts/payment-layout/payment-layout.module').then(mod => mod.PaymentLayoutModule),
		canActivate: [EnviromentsLoadGuard, LoggedInGuard],
		data: {
			roles: [ORG_ROLES.Owner],
		},
	},
	{
		path: 'cookie-disabled',
		loadChildren: () =>
			import('@ngx-common/pages/cookie-disabled/cookie-disabled.module').then(mod => mod.CookieDisabledModule),
	},
	{
		path: `error/:code`,
		loadChildren: () => import('./error-page/error-page.module').then(mod => mod.AppErrorPageModule),
		canActivate: [SEOGuard],
	},
	{
		path: '**',
		pathMatch: 'full',
		loadChildren: () => import('./error-page/error-page.module').then(mod => mod.AppErrorPageModule),
		canActivate: [DashboardErrorPagesRedirectsGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
