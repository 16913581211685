import { EProductType } from '@ngx-common/enums/product-type.enums';

export class MainLayoutRouting {
	public static MAIN = '';
	public static INDEX_DOCUMENTS = 'index-documents';
	public static NotFoundPage = 'error/404';
	// Analytics-page
	public static ANALYTICS = 'analytics';

	// Members
	public static MEMBERS = 'members';
	public static MEMBER = 'member';
	public static DEPARTMENT = 'department';
	public static Members_Profile_DeptId = ':deptId';
	public static Members_Profile_Email = ':email';
	public static Members_Department = `${MainLayoutRouting.DEPARTMENT}/${MainLayoutRouting.Members_Profile_DeptId}`;
	public static Members_Profile = `${MainLayoutRouting.DEPARTMENT}/${MainLayoutRouting.Members_Profile_DeptId}/${MainLayoutRouting.MEMBER}/${MainLayoutRouting.Members_Profile_Email}`;

	public static MEMBERPROFILE(deptId: string, email: string) {
		MainLayoutRouting.Members_Profile.replace(MainLayoutRouting.Members_Profile_DeptId, deptId);
		MainLayoutRouting.Members_Profile.replace(MainLayoutRouting.Members_Profile_Email, email);
		return `${MainLayoutRouting.MEMBERS}/${MainLayoutRouting.DEPARTMENT}/${deptId}/${MainLayoutRouting.MEMBER}/${email}`;
	}

	// Repositories
	public static REPOSITORIES = 'repositories';
	public static Repository_Management_ID = ':repositId';

	public static RepositoryProfile(repositId: string) {
		return `${MainLayoutRouting.REPOSITORIES}/${repositId}`;
	}

	// Policies
	public static POLICY = 'policies';
	public static SCANING = 'scanning';
	public static SECURITY = 'security';
	public static POLICYID = ':policyId';
	public static POLICIES = `${MainLayoutRouting.SCANING}/${MainLayoutRouting.POLICYID}`;

	public static PolicyScaning(policyId: number) {
		return `${MainLayoutRouting.POLICY}/${MainLayoutRouting.SCANING}/${policyId}`;
	}

	// Organization
	public static ORGANIZATION = 'organization';
	public static ORGANIZATION_DETAILS = 'organization/details';
	public static ORGANIZATION_OPENID = 'organization/open-id';

	// Gen-Ai
	public static AI_GRC = 'ai-grc';

	// Billing
	public static BILLING = 'billing';
	public static PaymentsHistoryPage = 'payments-history';
	public static PRODUCT_PARAM = ':product-param';
	public static PRODUCT = ':product';
	public static PAYMENT_MAIN_PAGE = `payments/${MainLayoutRouting.PRODUCT}/${MainLayoutRouting.PRODUCT_PARAM}/payment`;
	public static Update_Payment_Method = 'update-payment-method';

	public static CheckoutProductPage(product: EProductType) {
		return MainLayoutRouting.PAYMENT_MAIN_PAGE.replace(MainLayoutRouting.PRODUCT, product);
	}
}
