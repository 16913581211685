import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UrlLocationService } from '@ngx-common/services/url-location.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardErrorPagesRedirectsGuard implements CanActivate {
	constructor(private _urlLocationSvc: UrlLocationService) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let _safeLocation = this._urlLocationSvc.location;

		if (state.url.startsWith('/organization/billing')) {
			const urlComplete = state.url.replace('/organization/billing', '');
			_safeLocation.href = `/billing` + urlComplete;
			return false;
		}
		if (state.url.startsWith('/organization/payments-history')) {
			const urlComplete = state.url.replace('organization', 'billing');
			_safeLocation.href = urlComplete;
			return false;
		}
		return true;
	}
}
