import { DomSanitizer } from '@angular/platform-browser';

/**
 * Changes the icons of the Quill Editor toolbar buttons
 * @public
 * @returns the new toolbar icons configuration
 */
export function ChangeQuillEditorToolbarIcons(icons: any) {
	icons['bold'] = '<span class="material-icons">format_bold</span>';
	icons['italic'] = '<span class="material-icons">format_italic</span>';
	icons['underline'] = '<span class="material-icons">format_underlined</span>';
	icons['color'] = '<span class="material-icons">format_color_text</span>';
	icons['background'] = '<span class="material-icons">font_download</span>';
	icons['blockquote'] = '<span class="material-icons">format_quote</span>';
	icons['code-block'] = '<span class="material-icons">code</span>';
	icons['link'] = '<span class="material-icons">link</span>';
	icons['list']['bullet'] = '<span class="material-icons">format_list_bulleted</span>';
	icons['list']['ordered'] = '<span class="material-icons">format_list_numbered</span>';
	icons['align']['center'] = '<span class="material-icons">format_align_center</span>';
	icons['align']['justify'] = '<span class="material-icons">format_align_justify</span>';
	icons['align']['right'] = '<span class="material-icons">format_align_right</span>';
	icons['align'][''] = '<span class="material-icons">format_align_left</span>';
	return icons;
}

export function ModifyQuillEditorSubmittedTextForIframe(quillEditorContent: string, sanitizer: DomSanitizer) {
	// Get the styles URL for the quill editor
	const quillStylesLink = '<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.core.css">';

	// Combine the styles with the content
	const completeHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        ${quillStylesLink}
      </head>
      <body>
      <div class="ql-editor" style="padding: 0px; font-family: sans-serif;">${quillEditorContent ?? ''} </div>
      </body>
      </html>
    `;

	// Sanitize and assign to iframeContent
	return sanitizer.bypassSecurityTrustHtml(completeHtml);
}

/**
 * Quill Editor Configuration Modules:
 * 1. Text background color & text color
 * 2. Text decoration
 * 3. Text types
 * 4. List options
 * 5. Text alignment
 * 6. Text sizes
 * 7. Text fonts
 */
export const QuillEditorModules = {
	toolbar: [
		[{ color: [] }, { background: [] }], // text background color & text color

		['bold', 'italic', 'underline'], // text decoration

		['blockquote', 'code-block', 'link'], // text types

		[{ list: 'ordered' }, { list: 'bullet' }], // list options

		[{ align: [] }], // text alignment

		[{ size: ['small', false, 'large', 'huge'] }],
		[{ font: [] }], // text sizes & text fonts
	],
};
